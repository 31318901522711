import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";

import { makeStyles } from "@material-ui/core/styles";
import {
  MenuItem,
  Button,
  TextField,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  FormControl,
  Select,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import ImageIcon from "@material-ui/icons/Image";
import {
  getBlogData,
  getUserBlogs,
  setBlogUpdateTrigger,
  UpdateModalStatus,
} from "../global/action";
import { uploadToServer, createBlog, adminCreateBlog } from "../SDK/blog";
import { getCategory } from "../SDK/selectOptions";

import Sample1 from "../assets/sample/sample1.png";
import Sample2 from "../assets/sample/sample2.png";
import Sample3 from "../assets/sample/sample3.png";
import Sample4 from "../assets/sample/sample4.png";
import Sample5 from "../assets/sample/sample5.png";
import Sample6 from "../assets/sample/sample6.png";
import Sample7 from "../assets/sample/sample7.png";
import Sample8 from "../assets/sample/sample8.png";
import { Color } from "../assets/color";
import zIndex from "@material-ui/core/styles/zIndex";
import { set } from "lodash";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 800,
    width: 830,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    paddingBottom: 20,
    margin: "-20px auto 0",
    overflowX: "hidden",
    [theme.breakpoints.up("xs")]: {
      width: "96vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "90vw",
      maxWidth: 700,
    },
    [theme.breakpoints.up("lg")]: {
      width: 700,
    },
    "&:focus": {
      border: "1px solid rgba(0, 0, 0, 0.015)",
    },
    "& .MuiButton-fullWidth": {
      backgroundColor: "#2F80ED",
      color: "#fff",
    },
    "& .MuiButton-contained": {
      backgroundColor: "#2F80ED",
      color: "#fff",
    },
    "& .Mui-disabled": {
      color: "rgba(0, 0, 0, 0.4)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
  },
  action: {
    display: "flex",
    // flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    marginTop: 20,
  },
  links: {
    textDecoration: "none",
  },
  modalTitle: {
    textAlign: "center",
    marginTop: -40,
  },
  imgButton: {
    backgroundColor: "#2F80ED",
    color: "#fff",
    borderRadius: 5,
    width: 200,
    textAlign: "center",
    padding: 10,
    cursor: "pointer",
  },
  imgButton2: {
    color: "#2F80ED",
    borderRadius: 5,
    width: 200,
    textAlign: "center",
    padding: 10,
    cursor: "pointer",
  },
  linkAction: {
    cursor: "pointer",
    color: "#2F80ED",
  },
  close: {
    color: "#000",
    textAlign: "right",
    paddingRight: 20,
    fontSize: 36,
    cursor: "pointer",
    zIndex: 100,
  },
  coverImg: {
    width: "100%",
    height: "auto",
    maxHeight: 500,
    [theme.breakpoints.up("xs")]: {
      maxHeight: 360,
    },
  },
  formContainer: {
    position: "relative",
    overflow: "inherit",
    padding: 2,
    marginLeft: -1,
    display: "flex",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.up("xs")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "85vw",
      maxWidth: 600,
    },
    [theme.breakpoints.up("lg")]: {
      width: 600,
    },
  },
  contentForm: {
    // marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up("xs")]: {
      width: "90vw",
    },
    [theme.breakpoints.up("sm")]: {
      width: "85vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "85vw",
      maxWidth: 600,
    },
    [theme.breakpoints.up("lg")]: {
      width: 600,
    },
  },
  errorMsg: {
    color: Color.errorRed,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 250,
    },
  },
};

export const randomImageList = [
  Sample1,
  Sample2,
  Sample3,
  Sample4,
  Sample5,
  Sample6,
  Sample7,
  Sample8,
];

const AddPostModal = (props) => {
  const { openModal, setOpenModal, user, size, userBlogSize, dispatch } = props;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [textEditing, setTextEditing] = useState(false);
  const [coverMediaUrl, setCoverMediaUrl] = useState(""); // 用于存储图片和视频的URL（从服务器获取 S3 返回 的那种）
  const [mediaType, setMediaType] = useState("");
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(""); // for local development
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [title, setTitle] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [category, setCategory] = useState("");
  const [body, setBody] = useState("");

  const [randomImgIndex, setRandomImgIndex] = useState(null);
  const [bodyTextCount, setBodyTextCount] = useState(null);

  useEffect(() => {
    if (!localStorage.token) {
      history.push("/login");
    } else {
      getCategory().then(({ response }) => {
        setCategoryList(response);
      });
    }
    return () => {
      if (videoPreviewUrl) {
        URL.revokeObjectURL(videoPreviewUrl);
      }
    };
  }, [videoPreviewUrl]);

  const handleFileUpload = (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    var data = new FormData();
    data.append("file", file);

    uploadToServer(data)
      .then(({ response }) => {
        const isVideo = file.type.startsWith("video/");
        setCoverMediaUrl(response.url);
        setMediaType(isVideo ? "video" : "image");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 用于处理视频上传的方法
  const uploadVideo = (e) => {
    let file = e.target.files[0];
    var data = new FormData();
    data.append("file", file);

    // 创建本地URL以预览视频
    const localVideoURL = URL.createObjectURL(file);
    setVideoPreviewUrl(localVideoURL);

    // 假设你有一个处理视频上传的后端接口，这里没有，所以只是模拟一个
    mockVideoUpload(data)
      .then(({ response }) => {
        setCoverMediaUrl(response.url); // 假设响应中包含视频的URL
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mockVideoUpload = (file) => {
    // 模拟异步操作
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ url: "https://example.com/path/to/your/video.mp4" }); // 假设的视频URL
      }, 500);
    });
  };

  const handleSubmit = () => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    let content = {
      title,
      category,
      body,
      photos: mediaType === "image" ? [coverMediaUrl] : [],
      videos: mediaType === "video" ? [coverMediaUrl] : [],
    };

    const postFunction = user.role === "admin" ? adminCreateBlog : createBlog;

    postFunction(content)
      .then((res) => {
        // 清空表单
        setIsSubmitting(false);
        cleanUpAndCloseModal();
        dispatch(setBlogUpdateTrigger(true));
      })
      .catch((err) => {
        console.error(err);
        setIsSubmitting(false);
      });
  };

  const cleanUpAndCloseModal = () => {
    if (videoPreviewUrl) {
      URL.revokeObjectURL(videoPreviewUrl);
      setVideoPreviewUrl(""); // 清除本地状态
    }

    setOpenModal(false);
    setTextEditing(false);
    setCoverMediaUrl("");
    setVideoPreviewUrl("");
    setTitle("");
    setCategory("");
    setBody("");
    setRandomImgIndex(null);
    dispatch(UpdateModalStatus(false));
    dispatch(getUserBlogs(userBlogSize));
    dispatch(getBlogData(size));
  };

  const handleRandom = () => {
    let randomNum = Math.floor(Math.random() * randomImageList.length);
    if (randomImgIndex !== randomNum) {
      setRandomImgIndex(randomNum);
      setCoverMediaUrl(randomImageList[randomNum]);
      setMediaType("image");
    }
  };

  const handleClose = () => {
    console.log("close");
    cleanUpAndCloseModal();
  };

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      maxWidth={"lg"}
      fullScreen={fullScreen}
      aria-labelledby="responsive-dialog-title"
    >
      {
        <div className={classes.close} onClick={handleClose}>
          &times;
        </div>
      }
      <DialogTitle className={classes.modalTitle} id="responsive-dialog-title">
        {"Create New Post"}
      </DialogTitle>
      <DialogContent className={classes.paper}>
        {!textEditing ? (
          <>
            <div style={{ textAlign: "center", margin: "60px auto 0" }}>
              {coverMediaUrl &&
                (mediaType === "video" ? (
                  <video
                    src={coverMediaUrl}
                    className={classes.coverImg}
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    src={coverMediaUrl}
                    alt="cover"
                    className={classes.coverImg}
                  />
                ))}
              {!coverMediaUrl && (
                <ImageIcon
                  style={{
                    width: 120,
                    height: 120,
                    color: "#2E3A59",
                    marginTop: 20,
                  }}
                />
              )}
            </div>
            <div className={classes.action}>
              {coverMediaUrl || videoPreviewUrl ? (
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {randomImgIndex !== null ? (
                    <p className={classes.imgButton2} onClick={handleRandom}>
                      {"Change Image"}
                    </p>
                  ) : (
                    <div>
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept="image/*, video/*"
                        id="contained-button-file"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.type.startsWith("image/")) {
                              handleFileUpload(e);
                            } else if (file.type.startsWith("video/")) {
                              handleFileUpload(e);
                            }
                          }
                        }}
                      />
                      <label
                        htmlFor="contained-button-file"
                        className={classes.imgButton2}
                      >
                        {"Change Image/Video"}
                      </label>
                    </div>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => setTextEditing(true)}
                    className={classes.imgButton}
                  >
                    CONFIRM
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept="image/*, video/*"
                    id="contained-button-file"
                    onChange={handleFileUpload}
                  />
                  <label
                    htmlFor="contained-button-file"
                    className={classes.imgButton}
                  >
                    {coverMediaUrl ? "Change Media" : "Upload Image/Video"}
                  </label>
                  <p className={classes.linkAction} onClick={handleRandom}>
                    Or just use a random photo
                  </p>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className={classes.formContainer}>
              <div className={classes.row}>
                <FormControl required={true} className={classes.contentForm}>
                  <InputLabel id="experience">
                    Please select the topic of your post ?
                  </InputLabel>
                  <Select
                    labelId="experience"
                    sx={{
                      borderRadius: 0,
                      border: 2,
                      borderColor: "#E8E8E8",
                      "&:hover": { borderColor: "#377DED" },
                    }}
                    MenuProps={MenuProps}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {categoryList.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item.replace(/_/g, " ").toLowerCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className={classes.row}>
                <TextField
                  // variant="outlined"
                  margin="normal"
                  required
                  label="Title"
                  autoFocus
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{ marginBottom: 10, marginTop: 0 }}
                  fullWidth
                />
              </div>
              <TextField
                fullWidth
                multiline
                rows={15}
                value={body}
                onChange={(e) => {
                  let length = e.target.value.trim().length;
                  setBody(e.target.value);
                  setBodyTextCount(length);
                }}
                inputProps={{
                  maxLength: 2000,
                }}
                variant="outlined"
                placeholder="Please enter your content here..."
              />
              <div style={{ marginTop: 5 }}>
                {bodyTextCount > 2000 ? (
                  <span className={classes.errorMsg}>
                    Content has exceeded the 2000 character limit, please edit
                    the content in order to submit the form.
                  </span>
                ) : (
                  "Character limit: 2000"
                )}
              </div>
              <div className={classes.action}>
                <Button
                  variant="contained"
                  style={{ width: 300 }}
                  onClick={handleSubmit}
                  size="large"
                  disabled={bodyTextCount > 2000 || isSubmitting}
                >
                  CONFIRM
                </Button>
              </div>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
const mapStoreStateToProps = (state) => {
  return {
    user: state.userReducer.toJS().user,
    userBlogSize: state.userReducer.toJS().userBlogSize,
    userBlogCount: state.userReducer.toJS().userBlogCount,
    size: state.blogReducer.toJS().size,
  };
};

export default connect(mapStoreStateToProps)(AddPostModal);
