const postData = async (data) => {
    const URL = process.env.REACT_APP_CORNELL_API_URL || 'http://35.153.52.66/shap'
    console.log("============ postData URL start ============");
    console.log(URL);
    console.log("============ postData URL end ============");
    const res = await fetch(URL, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    });
    
    const result = await res.text();
    console.log("============ postData result start ============");
    console.log(result);
    console.log("============ postData result end ============");
    return result;
};

export default postData;